<template>
  <div class="web-header g-container">
    <a-button @click="this.$router.push({ name: 'Index' })">首页</a-button>
    <img style="background-color: aliceblue" alt="Vue logo" src="@/assets/logo.png" />
    <div class="web-title">
      欢迎来到有志青年共享
    </div>
    <p class="web-expression">😀 😃 😄 😁 😆 😅 😂 🤣</p>
    <a-tooltip placement="leftTop">
    <template #title><p style="font-size: 14px">快上车</p></template>
      <img class="img-expression" alt="开车" src="@/assets/开车.gif">
    </a-tooltip>
  </div>
</template>
<script>
import { Tooltip } from 'ant-design-vue'

export default {
  name: "WebHeader",
  components: {
    ATooltip: Tooltip,
},
}
</script>
<style scoped lang="scss">
.web-header {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  img {
    width: 100px;
    border-radius: 50%;
    margin: 0 16px 16px;
  }
  .web-title {
    font-size: 40px;
    color: #2c3e50;
    margin: 0 16px 16px;
  }
  .web-expression {
    font-size: 40px;
  }
  .img-expression {
    width: 120px;
    border-radius: 50px;
  }
  #title {
    font-size: 20px;
  }
  .chatgpt {
    font-size: 20px;
    color: #2c3e50;
    text-decoration: none;
  }
  .login-button {
  }
}
</style>