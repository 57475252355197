<template>
  <WebHeader/>
  <div class="g-container">
    <div class="my-article">
      <template v-if="article_view">
        <template v-if="article_input">
          <div class="edit">
            <a-button @click="edit()">编辑</a-button>
          </div>
          <div v-html="article_html" class="markdown-body"/>
        </template>
        <template v-else>
          <div class="article-enable">你无编辑权限</div>
        </template>
      </template>
      <template v-else>
        <ArticleEditor :articleInput="article_input" @submit="submit"/>
      </template>
    </div>

    <WebFooter/>
  </div>
</template>
<script>
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'
import articleApi from '@/api/article'
import {onMounted, ref} from 'vue'
import ArticleEditor from '@/components/ArticleEditor.vue'
import {useRouter} from "vue-router";

export default {
  name: 'MyArticle',
  props: {
    articleId: {
      type: String,
      required: true,
    }
  },
  components: {
    WebHeader,
    WebFooter,
    ArticleEditor
  },
  setup(props) {
    const article_html = ref('');
    const article_input = ref('');
    const router = useRouter()
    const load = () => {
      articleApi.getMyArticleInfor(props.articleId).then((res) => {
        if (res['resCode'] === 200) {
          res = res['resMsg']['data']
          article_html.value = res.article_html
          article_input.value = res.article_input
        } else {
          router.push({name: 'UserLogin', params: {name: 'MyArticle'}, query: { articleId: props.articleId} })
        }
      })
    }
    const article_view = ref(true)
    const edit = () => {
      article_view.value = !article_view.value
    }
    const submit = (content) => {
      const data = {
        article_id: props.articleId,
        article_name: content.title,
        article_input: content.input,
        article_html: content.html
      }
      articleApi.updateMyArticle(data).then(() => {
        article_view.value = true
        load()
      })
    }
    onMounted(() => {
      load()
    })
    return {
      article_html,
      article_view,
      article_input,
      edit,
      submit
    };
  }
}
</script>
<style scoped lang="scss">
@import 'github-markdown-css/github-markdown-light.css';

.my-article {
  color: #000;
  padding: 20px;
  text-align: left;

  .article-enable {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
}

.edit {
  text-align: right;
}
</style>