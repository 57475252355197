<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style scss="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: aliceblue;
  margin-top: 20px;
}
.g-container {
  margin: auto;
  padding-top: 16px;
  width: 1350px;
  background-color: aliceblue;
  border-radius: 5px;
}
</style>
