<template>
  <WebHeader/>
  <div class="g-container">
    <div class="ai-article">
      <div v-html="article_html"/>
    </div>
    <WebFooter/>
  </div>
</template>
<script>
import {ref, onMounted} from 'vue'
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'
import articleApi from '@/api/article'
import {useRouter} from 'vue-router'

export default {
  name: 'AiArticle',
  props: {
    articleId: {
      type: String,
      required: true,
    }
  },
  components: {
    WebHeader,
    WebFooter
  },
  setup(props) {
    const article_html = ref('');
    const router = useRouter()
    const load = () => {
      articleApi.getAiArticleInfor(props.articleId).then((res) => {
        if (res['resCode'] === 200) {
          article_html.value = res['resMsg']['data']
        } else {
          router.push({name: 'UserLogin', params: {name: 'AiArticle'}, query: { articleId: props.articleId}})
        }
      })
    }
    onMounted(() => {
      load()
    })
    return {
      article_html
    };
  },
}
</script>
<style scoped lang="scss">
.ai-article {
  color: #2c3e50;
  font-size: 24px;
  text-align: left;
  background-color: aliceblue;
  margin: 20px 200px;

  :deep(img) {
    margin: 100px auto;
    width: 940px;
    height: 600px !important;
    object-fit: cover;
  }
}
</style>