<template>
  <div class="web-footer">
    <div style="margin-right: 24px">法律声明&nbsp;&nbsp;备案号:
      <a style="color: #939393;" target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn">京ICP备2023006542号-1</a>
    </div>
    <div>
      <img src="../assets/备案图标.png" alt="">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010902000854" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color: #939393;">京公网安备 11010902000854号</p></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WebFooter'
}
</script>
<style scoped lang="scss">
.web-footer {
  display: flex;
  background-color: aliceblue;
  color: #2c3e50;
  font-size: 14px;
  padding: 10px 0;
  justify-content: center;
  text-align: center;
}
</style>