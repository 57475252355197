<template>
  <div class="g-container">
    <div class="login">
      <a-form :model="formState" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off">
        <div class="login-title">用户登录</div>
        <a-form-item label="Username" name="username"
          :rules="[{ required: true, message: 'Please input your username!' }]">
          <a-input v-model:value="formState.username" />
        </a-form-item>

        <a-form-item label="Password" name="password"
          :rules="[{ required: true, message: 'Please input your password!' }]">
          <a-input-password v-model:value="formState.password" />
        </a-form-item>

        <a-form-item name="remember" :wrapper-col="{ offset: 8, span: 12 }">
          <a-checkbox v-model:checked="formState.remember">Remember me</a-checkbox>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 8, span: 12 }">
          <a-button type="primary" class="submit-button" html-type="submit" @click="login">Submit</a-button>
        </a-form-item>
      </a-form>
    </div>
    <WebFooter />
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import WebFooter from '@/components/WebFooter.vue'
import loginApi from '@/api/login'
import { notification } from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter()
const route = useRoute()

const formState = reactive({
  username: '',
  password: '',
  remember: true,
})
const login = () => {
  if (formState.username === '') {
    alert('请输入用户名')
    return
  }
  if (formState.password === '') {
    alert('请输入密码')
    return
  }
  loginApi.userLogin(formState).then(res => {
    if (res['resCode'] === 200) {
      notification.success({
        message: '登录成功',
        description: '欢迎回来',
      })
      setTimeout(() => {
        const name = route.params
        if (route.query.articleId) {
          name["params"] = route.query
        }
        router.push(name)
      }, 1000)
    }
    else {
      notification.error({
        message: '登录失败',
        description: '用户名或密码错误',
      })
    }
  }).catch(err => {
    console.log(err)
  })
}
</script>
<style scoped lang="scss">
.login {
  display: flex;
  background-color: aliceblue;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-title {
    color: #2c3e50;
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 60px;
  }

  .submit-button {
    width: 90%;
  }
}
</style>