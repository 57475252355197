import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import MyVideo from '../views/MyVideo.vue'
import AiArticles from '../views/AiArticles.vue'
import AiArticle from '../views/AiArticle.vue'
import FavArticle from '../views/FavArticle.vue'
import RotatingSphere from '../views/RotatingSphere.vue'
import UserLogin from '@/views/UserLogin.vue'
import MyArticle from '@/views/MyArticle.vue'
import MyArticles from '@/views/MyArticles.vue'
import EditArticle from '@/views/EditArticle.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/login/:name',
    name: 'UserLogin',
    component: UserLogin,
    props: true
  },
  {
    path: '/videos',
    name: 'MyVideo',
    component: MyVideo
  },
  {
    path: '/ai_articles',
    name: 'AiArticles',
    component: AiArticles
  },
  {
    path: '/ai_article/:articleId',
    name: 'AiArticle',
    component: AiArticle,
    props: true
  },
  {
    path: '/favorite_article',
    name: 'FavoriteArticle',
    component: FavArticle,
  },
  {
    path: '/rotating_sphere',
    name: 'RotatingSphere',
    component: RotatingSphere
  },
  {
    path: '/my_articles',
    name: 'MyArticles',
    component: MyArticles
  },
  {
    path: '/my_article/:articleId',
    name: 'MyArticle',
    component: MyArticle,
    props: true
  },
  {
    path: '/edit_article/',
    name: 'EditArticle',
    component: EditArticle,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router