<template>
  <div class="videoes">
    <div class="video" v-for="(videoAddress, vi) in videoSortAddress" :key="vi">
      <VideoPLayer :video-address="videoAddress" :id ="vi"/>
    </div>
  </div>
</template>
<script>
import VideoPLayer from './VideoPLayer.vue'
import videoApi from '@/api/video.js'
import { ref, onMounted } from 'vue'
import {useRouter} from "vue-router"

export default {
  name: 'GameVideos',
  components: {
    VideoPLayer
  },
  setup() {
    const videoSortAddress = ref('')
    const router = useRouter()
    const load = () => {
      videoApi.getVideoUrl().then((res) => {
        if (res['resCode'] === 200) {
          const videoAddresses = res['resMsg']['data']
          videoSortAddress.value = videoAddresses.sort().reverse()
        } else {
          router.push({name: 'UserLogin', params: {name: 'MyVideo'}})
        }
    }) 
    }
    onMounted(() => {
      load()
    })
    
    return {
      videoSortAddress,
    }
  }
}
</script>
<style scoped lang="scss">
.videoes {
  display: flex;
  flex-wrap: wrap;
  .video {
    background-color: #2c3e50;
    border-radius: 5px;
    margin: 20px 0 10px 33px;
  }
}
</style>