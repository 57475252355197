import axios from 'axios'

const isLocal = window.location.hostname !== 'xzaizl.cloud' && window.location.hostname !== 'www.xzaizl.cloud'
const rest = axios.create({
  baseURL: isLocal ? 'http://localhost:8000/api' : 'https://xzaizl.cloud/api',
  timeout: 3000,
})
rest.interceptors.request.use(config => {

  config.headers['X-CSRFToken'] = getCookie('csrftoken')
  return config
})
function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) {
      return parts.pop().split(';').shift()
  }
}
export default rest
